import { instance } from '@/utils/http.js'

// 获取考试详情
export const getTestPaperInfo = function (data) {
  return instance({
    url: '/api/v1/java/info',
    method: 'post',
    data
  })
}

// 获取考试排名
export const getSort = function (data) {
  return instance({
    url: '/api/v1/java/sort',
    method: 'post',
    data
  })
}

// 获取正态分布
export const getZhengtai = function (data) {
  return instance({
    url: '/api/v1/java/gaussianDistribution',
    method: 'post',
    data
  })
}

// 获取班级数据
export const getClassScores = function (data) {
  return instance({
    url: '/api/v1/java/getClassScores',
    method: 'post',
    data
  })
}

// 获取题目知识点得分
export const getQuestionKnowledgeScores = function (data) {
  return instance({
    url: '/api/v1/java/statics',
    method: 'post',
    data
  })
}

// 获取试卷知识点得分率
export const getPaperKnowledgeScores = function (data) {
  return instance({
    url: '/api/v1/java/paperKnowledge',
    method: 'post',
    data
  })
}