<template>
  <div>
    <div class="bar-vertical-pic"
         style="width: 100%">
      <div id="distributeMathBar"
           style="width:60%;height: 270px"></div>
    </div>
  </div>
</template>
<script>
import { getZhengtai } from '@/api/analyze.js'

export default {
  name: 'DistributeMathBar',
  description: '残差分布图',
  mixins: [],
  components: {},
  props: {
    objInfo: Object
  },
  data () {
    return {
      seriesValuedata: [],
      listData: [],
      xAxis: []
    }
  },
  //监听，解决第一次加载页面时无法渲染第一个echart图,和切换图例时，点击搜索按钮无法加载数据问题
  watch: {
    objInfo () {
      this.initFailureBarVertical()
    }
  },
  mounted () {

    this.initFailureBarVertical()
  },
  methods: {
    sum (array) {
      let s = 0
      array.forEach(function (val, idx, arr) {
        s += Number(val)
      }, 0)
      return s
    },
    //求和
    sumXY (x, y) {
      return Number(x) + Number(y)
    },
    //求平方
    square (x) {
      return Number(x) * Number(x)
    },
    async initFailureBarVertical () {
      let that = this
      let params = {
        testPaperId: this.$route.query.test_paper_id,
      }
      const { data: datas } = await getZhengtai(params)
      this.seriesValuedata = datas
      that.seriesValuedata.forEach((item, index) => {
        that.listData.push(Number(item.userScore))
      })


      //
      let objGroup = that.listData.reduce(function (obj, name) {
        obj[name] = obj[name] ? ++obj[name] : 1
        return obj
      }, {})

      //求数组的最大值和最小值
      let max = Math.max(...that.listData)
      let min = Math.min(...that.listData)
      //x轴最大最小前后范围
      let dataRangeMinOP = 2
      let dataRangeMaXOP = 2.1

      //间距 ，X轴的步距
      let dataRangeOP = 0.1
      //小数点位数.这个要和数据精确到哪一位相匹配
      let Xpiont = 1
      //处理x轴，把数据范围内的x轴求出来，并设置值轴没有的值为空，按顺序对应
      for (let i = min - dataRangeMinOP; i <= max + dataRangeMaXOP; i += dataRangeOP) {
        let str = i.toFixed(Xpiont).toString()
        that.xAxis.push(str)
        if (objGroup[str] == null) {
          objGroup[str] = 0
        }
      }
      let barYaxis = []
      Object.keys(objGroup).sort(function (a, b) {
        return parseFloat(a) - parseFloat(b)
      }).map(key => {
        let num = Math.floor((objGroup[key] / that.listData.length * 100) * 100) / 100
        barYaxis.push(num)
      })
      //正太曲线计算的基本数据和方法
      let avg = 0
      let stdev = 0
      avg = that.sum(that.listData) / that.listData.length

      let mean = that.listData.reduce(that.sumXY) / that.listData.length

      let deviations = that.listData.map(function (x) {
        return x - mean
      })

      stdev = Math.sqrt(deviations.map(that.square).reduce(that.sumXY) / (that.listData.length - 1))

      //计算正太曲线
      function NDjs (array) {
        let NDarr = []
        for (let i = 0; i < array.length; i++) {
          let ND = (Math.sqrt(2 * Math.PI) * stdev) * Math.pow(Math.E, (-(Math.pow(array[i] - avg, 2) / (2 * Math.pow(stdev, 2)))))
          NDarr.push(ND)
        }
        return NDarr
      }

      let lineYaxis = NDjs(that.xAxis)

      //配置项，本身项目是可以动态在页面配置修改这些属性的，贴到这里用了默认值
      let opacityOption = 'off'
      let opacity = 0.5
      if (opacityOption == 'off') {
        opacity = 0
      }
      let endPositionOption = 'all'
      let endPositionPercentum = ''
      let endPosition
      if (endPositionOption == 'all') {
        endPosition = 100
      } else if (endPositionOption == 'third') {
        endPosition = 29
      } else {
        endPosition = endPositionPercentum
      }

      let persents = 'on'
      let format1
      let format2
      if (persents == 'on') {
        format1 = '{value} %'
        format2 = '{c} %'
      }
      let data = []
      let lineDataSet = {
        type: 'line',
        symbol: 'none', // 默认是空心圆（中间是白色的），改成实心圆 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
        smooth: true,
        yAxisIndex: 1,
        areaStyle: {
          opacity: opacity
        },
        data: lineYaxis,  //正态分布的值
        name: '正态分布',
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#FD723CFF' // 0% 处的颜色
                },
                {
                  offset: 0.45,
                  color: '#FD723CFF' // 45% 处的颜色
                },
                {
                  offset: 0.85,
                  color: '#FF5B82FF' // 85% 处的颜色
                },
                {
                  offset: 1,
                  color: '#FF5B82FF' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            },
            label: {
              formatter: format2,
              show: false, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                fontSize: 16
              }
            }
          }
        }
      }
      let barDataSet = {
        type: 'bar',
        smooth: true,
        yAxisIndex: 0,
        areaStyle: {
          opacity: opacity
        },
        data: barYaxis,  //柱子的值
        name: '实际分布(%)',
        itemStyle: {
          normal: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#79c7f3FF' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#7E7CEB' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            },
            label: {
              formatter: format2,
              show: false, //开启显示
              position: 'top', //在上方显示
              textStyle: { //数值样式
                fontSize: 16
              }
            }
          }
        }
      }

      data.push(lineDataSet, barDataSet)

      let myChart = that.$echarts.init(document.getElementById('distributeMathBar'))
      let option = {
        type: 'scroll',
        title: {
          text: ''
        },
        //区域缩放
        dataZoom: [
          {
            type: 'inside',
            show: false,
            xAxisIndex: [0],
            start: 0,
            end: endPosition,
            borderColor: '#F5A9D0',
            backgroundColor: '#F5A9D0'
          },
          {
            show: false,
            type: 'slider',
            xAxisIndex: [0],
            start: 0,
            end: endPosition
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['正态分布', '实际分布(%)']
        },
        xAxis: {
          name: '分数',
          nameTextStyle: {
            align: 'left',
            fontSize: 14,
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            color: '#989DAA'
          },
          boundaryGap: false,
          type: 'category',
          data: that.xAxis
        },
        yAxis: [{
          name: '频数',
          nameTextStyle: {
            align: 'right',
            fontSize: 14,
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            color: '#989DAA'
          },
          type: 'value',
          axisLabel: {
            formatter: format1
          }
        }, {
          show: false,
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          }
        }],
        grid: [{
          show: false,
          top: '15%',
          bottom: '10%',
          left: '5%',
          right: '12%'
        }],
        series: data
      }
      myChart.setOption(option)
    }
  }

}
</script>
<style lang="scss" scoped>
.bar-vertical-pic {
  padding: 0 0px;
}
</style>

